import React, {useState} from "react";
import Circle from "./components/Circle";
import Controls from "./components/Controls";
import {animate} from "framer-motion";
import styles from "./App.css";

// -------------- PLAN ---------------- //

// OUR APP WILL CONSIST OF 2 COMPONENTS

// COMPONENT 1: LARGE CIRCLE WITH A WHITE BORDER AND COLOURED BACKGROUND
// THE BACKGROUND COLOUR OF THE CIRCLE WILL BE CONTROLLED BY STATE

// COMPONENT 2: CONTROLS - CONSISTING OF 6 COLOURED BUTTONS
// ON CLICK THE COLOUR OF THE BUTTON WILL BE STORED TO STATE AND USED AS THE BACKGROUND FOR THE MAIN LARGE CIRCLE

// THE CSS FOR EACH COMPONENT WILL BE STORED IN ITS OWN FILE - AS PER COMPONENT BEST PRACTICE

// ----------------- CODE -------------- //


function App() {

  const colourChoices = ["#f94144", "#f3722c", "#f8961e", "#f9844a", "#f9c74f", "#90be6d", "#43aa8b", "#4d908e", "#577590", "#277da1"];
  const [colour, setColour] = useState(colourChoices[0]);

  // trasition between old and new colour can be done with either css transitions OR using tweening between prev and new state
  const buttonRemoveActive = (button) => {
    button.classList.remove('active');
  }
  
  const buttonClickHandler = (event) => {
    let allButtons = document.querySelectorAll(".button");
    allButtons.forEach(buttonRemoveActive)
    event.target.classList.add("active")
    setColour(event.target.id);
  }

  return (
    <>
    <div className="main">
      <div className="container">
        <Circle colour={colour} />
        <Controls choices={colourChoices} clickHandler={buttonClickHandler} />
      </div>
    </div>
    </>
  );
}

export default App;