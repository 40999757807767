import React from "react";
import styles from "./Controls.css";

function Controls(props) {
  // LOOP THROUGH OUR AWAY OF COLOURS (PASSED DOWN FROM APP.JS) AND GENERATE A BUTTON FOR EACH

  const generatedButtons = props.choices.map(thisColour => {
    return <div key={thisColour} id={thisColour} className="button" style={{ backgroundColor: thisColour }} onClick={props.clickHandler}></div>
  });


  return (
    <div className="controls-container">
      {generatedButtons}
    </div>
    
  );
};

export default Controls;