import React from "react";
import styles from "./Circle.css";

function Circle(props) {
  /* console.log(props.colour) */
  return (
    <div className="circle" style={{ backgroundColor: props.colour }}></div>
  );
};

export default Circle;